import React from 'react';
import Header from './components/Header';
import Profile from './components/Profile';
import Work from './components/Work';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Links from './components/Links';
import Footer from './components/Footer'; // Import Footer component

const App = () => (
  <div className="container">
    <Header />
    <Profile />
    <Work />
    <Projects />
    <Blog />
    <Links />
    <Footer /> 
  </div>
);

export default App;