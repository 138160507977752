import React, { useEffect, useRef, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_TYPING_SPEED = 40;
const DEFAULT_BACKSPACE_SPEED = 40;
const DEFAULT_PAUSE_AFTER_TYPING = 1000;
const DEFAULT_PAUSE_AFTER_ERASING = 4000;

const greetings = [
  { before: 'Hello, my name is ', name: 'Dylan Bradley', after: '' }, // English
  { before: 'Hola, mi nombre es ', name: 'Dylan Bradley', after: '' }, // Spanish
  { before: 'Bonjour, je m\'appelle ', name: 'Dylan Bradley', after: '' }, // French
  { before: 'Hallo, mein Name ist ', name: 'Dylan Bradley', after: '' }, // German
  { before: 'Ciao, mi chiamo ', name: 'Dylan Bradley', after: '' }, // Italian
  { before: 'Olá, meu nome é ', name: 'Dylan Bradley', after: '' }, // Portuguese
  { before: 'Hej, jag heter ', name: 'Dylan Bradley', after: '' }, // Swedish
  { before: 'Привет, меня зовут ', name: 'Dylan Bradley', after: '' }, // Russian
  { before: 'こんにちは、私の名前は ', name: 'Dylan Bradley', after: '' }, // Japanese
  { before: '你好，我叫 ', name: 'Dylan Bradley', after: '' }, // Chinese
  { before: '안녕하세요, 제 이름은 ', name: 'Dylan Bradley', after: '' }, // Korean
  { before: 'مرحبا، اسمي ', name: 'Dylan Bradley', after: '' }, // Arabic
  { before: 'שלום, שמי ', name: 'Dylan Bradley', after: '' }, // Hebrew
  { before: 'नमस्ते, मेरा नाम है ', name: 'Dylan Bradley', after: '' }, // Hindi
  { before: 'سلام، میرا نام ہے ', name: 'Dylan Bradley', after: '' }, // Urdu
  { before: 'Բարև, իմ անունը է ', name: 'Dylan Bradley', after: '' }, // Armenian
  { before: 'Përshëndetje, unë quhem ', name: 'Dylan Bradley', after: '' },
];

const phases = {
  TYPING: 'TYPING',
  ERASING: 'ERASING',
};

const initialState = {
  text: '',
  phase: phases.TYPING,
  greetingIndex: 0,
  charIndex: 0,
  pause: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TYPE':
      const currentGreeting = greetings[state.greetingIndex];
      const fullText = currentGreeting.before + currentGreeting.name + currentGreeting.after;
      const newChar = fullText[state.charIndex];
      const newText = state.text + newChar;

      if (state.charIndex + 1 >= fullText.length) {
        return { ...state, text: newText, charIndex: state.charIndex + 1, pause: true, phase: phases.ERASING };
      }

      return { ...state, text: newText, charIndex: state.charIndex + 1 };

    case 'ERASE':
      if (state.text.length > 0) {
        return { ...state, text: state.text.slice(0, -1), charIndex: state.charIndex - 1 };
      } else {
        return {
          ...initialState,
          greetingIndex: (state.greetingIndex + 1) % greetings.length,
          phase: phases.TYPING,
          pause: true,
        };
      }

    case 'UNPAUSE':
      return { ...state, pause: false };

    default:
      return state;
  }
};

const useTypingEffect = (typingSpeed, backspaceSpeed, pauseAfterTyping, pauseAfterErasing) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const animate = () => {
      if (state.pause) {
        const pauseDuration = state.phase === phases.TYPING ? pauseAfterTyping : pauseAfterErasing;
        timeoutRef.current = setTimeout(() => dispatch({ type: 'UNPAUSE' }), pauseDuration);
      } else {
        const actionType = state.phase === phases.TYPING ? 'TYPE' : 'ERASE';
        const speed = state.phase === phases.TYPING ? typingSpeed : backspaceSpeed;
        timeoutRef.current = setTimeout(() => dispatch({ type: actionType }), speed);
      }
    };

    animate();

    return () => clearTimeout(timeoutRef.current);
  }, [state, typingSpeed, backspaceSpeed, pauseAfterTyping, pauseAfterErasing]);

  return state;
};

const Profile = ({
  typingSpeed = DEFAULT_TYPING_SPEED,
  backspaceSpeed = DEFAULT_BACKSPACE_SPEED,
  pauseAfterTyping = DEFAULT_PAUSE_AFTER_TYPING,
  pauseAfterErasing = DEFAULT_PAUSE_AFTER_ERASING,
}) => {
  const state = useTypingEffect(typingSpeed, backspaceSpeed, pauseAfterTyping, pauseAfterErasing);
  const currentGreeting = greetings[state.greetingIndex];
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      // Handle resize logic if needed
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderText = () => {
    const beforeLength = currentGreeting.before.length;
    const nameLength = currentGreeting.name.length;
    const typedText = state.text;

    return (
      <div className="animated-text">
        {typedText.slice(0, beforeLength)}
        <span className="gradient-text">
          {typedText.slice(beforeLength, beforeLength + nameLength)}
        </span>
        {typedText.slice(beforeLength + nameLength)}
        <span className="cursor">&nbsp;</span>
      </div>
    );
  };

  return (
    <section className="profile">
      <div className="animated-text-container" ref={containerRef}>
        {renderText()}
      </div>
      <div className="profile-info">
        <span>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '8px' }} aria-label="Location" />
          <strong>London, England</strong>
        </span>
        <span>
          <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '8px' }} aria-label="Occupation" />
          <strong>Working Professional</strong>
        </span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
      <hr className="hr-SectionDivider" />
    </section>
  );
};

export default Profile;