import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Links = () => (
  <section className="links">
    <a href="https://icloud.com">
      <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px', color: '#ffffff' }} /> {/* White for email */}
    </a>
    <a href="https://x.com">
      <FontAwesomeIcon icon={faXTwitter} style={{ marginRight: '8px', color: '#ffffff' }} /> {/* White for Twitter */}
    </a>
    <a href="https://github.com">
      <FontAwesomeIcon icon={faGithub} style={{ marginRight: '8px', color: '#ffffff' }} /> {/* White for GitHub */}
    </a>
    <a href="https://linkedin.com">
      <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '8px', color: '#ffffff' }} /> {/* White for LinkedIn */}
    </a>
  </section>
);

export default Links;