const Blog = () => (
  <section className="blog">
    <h1>My Blog</h1>
    <div className="blog-item">
      <a href="https://example.com/post1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</a>
      <span className="date">Sep 3, 2024</span>
    </div>
    <div className="blog-item">
      <a href="https://example.com/post2">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</a>
      <span className="date">Sep 10, 2024</span>
    </div>
    <div className="blog-item">
      <a href="https://example.com/post3">I might be one of the biggest geniuses</a>
      <span className="date">Sep 10, 2024</span>
    </div>
    <div className="blog-item">
      <a href="https://example.com/post4">Of the modern age!</a>
      <span className="date">Sep 10, 2024</span>
    </div>
    <a href="#all-posts" className="button-link"><strong>All Posts →</strong></a>
  </section>
);

export default Blog;